import isOutsideDataCellClick from '../../rework/utils/isOutsideDataCellClick';
function handleKeyClickFactory(hot) {
    function handleKeyClick(event) {
        if (!hot)
            return;
        if (event.key === 'Enter') {
            const selected = hot.getSelected();
            if (!selected)
                return;
            const visualRow = selected[0][0];
            const visualColumn = selected[0][1];
            if (isOutsideDataCellClick(visualRow, visualColumn))
                return;
            const cellMeta = hot.getCellMeta(visualRow, visualColumn);
            if (cellMeta.type === 'farmdok.checkbox.optional' &&
                typeof cellMeta.renderEmpty === 'function' &&
                !cellMeta.renderEmpty(visualRow, hot)) {
                const selectValue = hot.getDataAtCell(visualRow, visualColumn);
                hot.setDataAtRowProp(visualRow, 'select', !selectValue);
            }
        }
    }
    return handleKeyClick;
}
// add these hooks to your handsontable instance
// additionally add hooks from rework/feature/keyboardNavigation
const hooks = {
    beforeKeyDown: { handleKeyClickFactory },
};
export default hooks;
