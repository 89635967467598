import _isEqual from 'lodash.isequal';
import difference from './differenceByKeys/differenceArrayByKeys';
export default function detectChanges(oldData, newData, columns, hot) {
    const compareKeys = columns.map((col) => col.data).filter((data) => typeof data === 'string');
    return difference(oldData, newData, compareKeys).map(toVisualRow(hot)).filter(byCellData(hot));
}
export function toVisualRow(hot) {
    return (change) => {
        const [physicalRow, key, newValue] = change;
        const visualRow = hot.toVisualRow(physicalRow);
        return [visualRow, key, newValue];
    };
}
export function byCellData(hot) {
    return (change) => {
        const [visualRow, key, newValue] = change;
        const oldValue = hot.getDataAtRowProp(visualRow, key);
        return !_isEqual(oldValue, newValue);
    };
}
