var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { lusolve } from 'mathjs';
import moment from 'moment';
import Vue from 'vue';
import numbro from '@/initNumbro';
import { ACTIVITY_ROUGH_GUIDS } from '@/shared/constants';
import baseWorkflowStore from '../../store/baseWorkflowStore';
import { ZONE_GENERATION_MODE_SATELLITE, ZONE_GENERATION_MODE_UPLOAD } from '../../store/baseWorkflowStore/common';
import { WorkflowKeyForRoutes } from '../../types';
const moduleGetters = Object.assign(Object.assign({}, baseWorkflowStore.getters), { timestampsByCoverageRatio: (state) => (coverageRatio) => Object.entries(state.multiPolyTimestamps.current)
        .filter(([key]) => key.endsWith(`${coverageRatio}`))
        .map(([, value]) => value), 
    // ui states
    paginationNextEnabled: (state) => {
        if (state.selectedFields.length === 0) {
            return false;
        }
        if (state.selectedFields.length !== Object.keys(state.multiPolyTimestamps.current).length) {
            return false;
        }
        if (state.paginationStep <= 1) {
            return true;
        }
        if (state.paginationStep === 2 &&
            state.zoneGenerationMode === ZONE_GENERATION_MODE_UPLOAD &&
            Object.values(state.uploadedZonesByFilename).length === 0) {
            return false;
        }
        if (state.paginationStep === 2 && !state.heatmaps.fetching) {
            return true;
        }
        if (state.paginationStep === 3 && state.calculation.averageDosage != null) {
            return true;
        }
        if (state.paginationStep === 4 && state.stepsCompleted) {
            return true;
        }
        return false;
    }, paginationNextDisabledReason: (state, getters) => {
        if (getters.paginationNextEnabled) {
            return null;
        }
        switch (state.paginationStep) {
            case 1:
                return Vue.i18n.translate('Bitte w\u00e4hle ein Feld aus, bevor du fortfährst.');
            case 3:
                return Vue.i18n.translate('Bitte w\u00e4hle ein Produkt und eine Produktmenge aus, bevor du fortfährst.');
            default:
                return null;
        }
    }, 
    // satellite images
    selectedIndexType: (state) => state.selectedIndexType, coverageRatio: (state, getters) => getters.selectedIndexType.includes('DNN_') ? 0 : 2, selectedQuantisationCode: (state) => state.selectedQuantisationCode, selectedHeatmapTimestamp: (state) => state.selectedHeatmapTimestamp, selectedHeatmapDbId: (state) => state.selectedHeatmapDbId, heatmapTimestampSelectedIndex: (state) => state.heatmapTimestampSelectedIndex, heatmapTimestampManuallySelected: (state) => state.heatmapTimestampManuallySelected, availableTimestamps: (state, getters) => {
        const timeArray = [];
        const mapping = {};
        getters.timestampsByCoverageRatio(getters.coverageRatio).forEach((polygon) => {
            polygon.availableData.forEach((data) => {
                const currentTimestamp = data.timestamp;
                const currentDbID = data.dbId;
                const currentDay = moment.unix(data.timestamp).startOf('day').unix();
                let dayIsInArray = false;
                timeArray.forEach((timestamp) => {
                    const day = moment.unix(timestamp).startOf('day').unix();
                    if (currentDay === day) {
                        dayIsInArray = true;
                    }
                });
                if (!dayIsInArray) {
                    timeArray.push(currentTimestamp);
                    mapping[currentTimestamp] = currentDbID;
                }
            });
        });
        return {
            timeArray: timeArray.sort((a, b) => a - b),
            mapping,
        };
    }, 
    // uploaded zones
    uploadedZonesByFilename: (state) => state.uploadedZonesByFilename, 
    // dosage calculation
    calculation: (state) => state.calculation, zonesByUploadedZones(state) {
        const zones = {};
        Object.values(state.uploadedZonesByFilename).forEach((geoJson) => {
            geoJson.features.forEach((feature) => {
                const color = feature.properties.fill;
                if (zones[color] == null) {
                    zones[color] = {
                        size: 0,
                        color,
                        name: numbro(feature.properties.RATE || 0).format(),
                        rate: feature.properties.RATE || 0,
                    };
                }
                if (typeof feature.properties.size === 'number') {
                    zones[color].size += feature.properties.size / 10000;
                }
            });
        });
        return Object.values(zones)
            .filter((zone) => zone.size > 0)
            .sort((first, second) => {
            if (second.rate && first.rate) {
                return second.rate - first.rate;
            }
            return -1;
        });
    },
    zones(state, getters) {
        if (state.zoneGenerationMode === ZONE_GENERATION_MODE_SATELLITE) {
            return getters.zonesByHeatmaps;
        }
        if (state.zoneGenerationMode === ZONE_GENERATION_MODE_UPLOAD) {
            return getters.zonesByUploadedZones;
        }
        return [];
    },
    zoneDosage(state, getters) {
        let averageDosage = 0;
        if (state.calculation.averageDosage && state.calculation.averageDosage > 0) {
            ({ averageDosage } = state.calculation);
        }
        const zones = getters.zones.filter((zone) => zone.size > 0);
        if (zones.length === 0) {
            return null;
        }
        if (state.calculation.manualDosage != null) {
            return state.calculation.manualDosage;
        }
        const matrix = [];
        const vector = [];
        matrix.push(zones.map((zone) => zone.size));
        vector.push(zones.reduce((sum, zone) => sum + zone.size, 0) * averageDosage);
        for (let x = 0; x < zones.length - 1; x += 1) {
            const row = [];
            for (let y = 0; y < x; y += 1) {
                row.push(0);
            }
            if (state.calculation.strategy === 'quality') {
                row.push(1 - state.calculation.zoneDifference);
                row.push(-1);
            }
            else if (state.calculation.strategy === 'balancing') {
                row.push(-1);
                row.push(1 - state.calculation.zoneDifference);
            }
            for (let y = x + 2; y < zones.length; y += 1) {
                row.push(0);
            }
            matrix.push(row);
            vector.push(0);
        }
        // @ts-ignore // needed for vue_pages (AgriDat project)
        const dosages = lusolve(matrix, vector).map(([dosage]) => dosage);
        let emptyZones = 0;
        return getters.zones.map((zone, index) => {
            let dosage = 0;
            if (zone.size > 0) {
                // @ts-ignore // TODO: fix this
                dosage = dosages[index - emptyZones];
            }
            else {
                emptyZones += 1;
            }
            return {
                color: getters.zones[index].color,
                name: getters.zones[index].name,
                dosage,
                n: dosage * state.calculation.n,
                p: dosage * state.calculation.p,
                k: dosage * state.calculation.k,
            };
        });
    }, taskDataAsync: (state, getters, rootState, rootGetters) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
        yield dispatch('activityTypes/subscribe');
        const { currentHeatmaps, zoneDosage, selectedTaskDate } = getters;
        const { zoneGenerationMode, uploadedZonesByFilename, selectedFields, selectedIndexType, selectedHeatmapDbId, selectedHeatmapTimestamp, selectedQuantisationCode, calculation, selectedCompany, } = state;
        const companyId = selectedCompany.id ? selectedCompany.id : rootState.auth.currentCompanies[0].id;
        const materials = [];
        if (calculation.material.id != null &&
            calculation.material.id !== 'manual' &&
            typeof calculation.averageDosage === 'number' &&
            calculation.averageDosage > 0) {
            materials.push({
                workingMeanId: calculation.material.id,
                amount: calculation.averageDosage,
                unitId: calculation.unit,
            });
        }
        let geoJson = null;
        if (zoneGenerationMode === ZONE_GENERATION_MODE_SATELLITE) {
            geoJson = currentHeatmaps;
        }
        if (zoneGenerationMode === ZONE_GENERATION_MODE_UPLOAD) {
            geoJson = uploadedZonesByFilename;
        }
        const processOrder = yield rootGetters['auth/processOrderByCompanyIdAndNameAndTypeAsync'](rootState.auth.currentCompanies[0].id, rootState.auth.currentProcessOrderName, 'service');
        const timeStartDate = selectedTaskDate || new Date();
        const timeStart = Math.floor(timeStartDate.getTime() / 1000);
        const taskData = {
            version: '2.0',
            data: {
                companyId,
                processOrderId: processOrder.id,
                activityId: rootGetters['activityTypes/byRoughAndFineId'](ACTIVITY_ROUGH_GUIDS.FEMI).id,
                timeStart,
                state: 'planned',
                fields: selectedFields.map((guid) => ({
                    fieldId: guid,
                    processedArea: rootGetters.fields[guid].fieldSize,
                })),
                workingMeans: materials,
                applicationMap: {
                    additionalData: {
                        fields: selectedFields.map((guid) => {
                            const field = {
                                id: guid,
                            };
                            if (zoneGenerationMode === ZONE_GENERATION_MODE_SATELLITE) {
                                // @ts-ignore // TODO: fix this
                                field.geoJsonId = Object.keys(currentHeatmaps).find((heatmapId) => heatmapId.startsWith(guid)) || null;
                            }
                            return field;
                        }),
                        zoneGenerationMode,
                        selectedIndexType,
                        selectedHeatmapDbId,
                        selectedHeatmapTimestamp,
                        selectedQuantisationCode,
                        calculation,
                        zoneDosage,
                    },
                    geoJson,
                    companyId,
                    workflowKey: WorkflowKeyForRoutes.FERTILIZATION,
                },
            },
        };
        return taskData;
    }) });
export default moduleGetters;
