var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import calculateAmountsAndUnits from '@/activities/utils/amountsAndUnits/calculateAmountsAndUnits';
export default function tableDataActivitiesImportProductService() {
    /* -- METHODS -- */
    function convertToActivityImportProductTableData(activityImportProduct, processedArea, units, productService, productStorageDropdownItem, isLoading) {
        if (!activityImportProduct)
            return undefined;
        const { amount, unitId } = activityImportProduct, restOfActivityImportProduct = __rest(activityImportProduct, ["amount", "unitId"]);
        const amountFallback = amount !== null && amount !== void 0 ? amount : 0;
        const unitIdFallback = findUnitIdWithFallback(activityImportProduct, productService);
        const amountsAndUnits = calculateAmountsAndUnits(amountFallback, unitIdFallback, processedArea, units);
        // there is no pesticide info in ImportActivityProduct
        const pesticideIndicationDropdownItem = undefined;
        return Object.assign(Object.assign(Object.assign(Object.assign({}, restOfActivityImportProduct), { productStorageDropdownItem: productStorageDropdownItem !== null && productStorageDropdownItem !== void 0 ? productStorageDropdownItem : undefined, pesticideIndicationDropdownItem }), amountsAndUnits), { isLoading });
    }
    function findUnitIdWithFallback(activityImportProduct, productService) {
        const { productId, unitId } = activityImportProduct;
        if (unitId)
            return unitId;
        if (!productId)
            return undefined;
        const product = productService.findProductById(productId);
        return product === null || product === void 0 ? void 0 : product.unitId;
    }
    function dummyProductForAddProductButton() {
        return {
            productId: '',
            productStorageDropdownItem: 'HOT_DISPLAY_BUTTON_RENDERER',
            pesticideIndicationDropdownItem: undefined,
            amountUnitRelative: {
                amount: null,
                unitId: null,
                isFixed: false,
            },
            amountUnitTotal: {
                amount: null,
                unitId: null,
                isFixed: false,
            },
            isLoading: false,
        };
    }
    return {
        convertToActivityImportProductTableData,
        dummyProductForAddProductButton,
    };
}
