var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { fetchSharedActivityTypes } from '@/shared/api/rest/RestApi';
import subscribableData from '@/shared/mixins/store/subscribableData/index';
export function fetchAll(context, axiosOptions) {
    var _a, _b;
    return __awaiter(this, void 0, void 0, function* () {
        const fieldSharingCompanyId = (_b = (_a = context.rootState.auth.currentCompanies) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.id;
        const { data } = yield fetchSharedActivityTypes(fieldSharingCompanyId, axiosOptions);
        return data;
    });
}
function fetchByIds(
// eslint-disable-next-line @typescript-eslint/no-unused-vars
ids, 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
context, 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
axiosOptions) {
    return __awaiter(this, void 0, void 0, function* () {
        throw new Error('NOT IMPLEMENTED');
    });
}
export const subscribableStore = subscribableData(fetchAll, fetchByIds);
