import convertToComparatorReturnValue from '../../features/columnSorting/convertToComparatorReturnValue';
function compareFunctionFactory(sortOrder, 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
columnMeta) {
    return function comparator(value, nextValue) {
        var _a, _b;
        const name = (_a = value === null || value === void 0 ? void 0 : value.name) !== null && _a !== void 0 ? _a : '';
        const nameNext = (_b = nextValue === null || nextValue === void 0 ? void 0 : nextValue.name) !== null && _b !== void 0 ? _b : '';
        const result = name.localeCompare(nameNext);
        if (sortOrder === 'asc') {
            return convertToComparatorReturnValue(result);
        }
        return convertToComparatorReturnValue(result * -1);
    };
}
export default {
    compareFunctionFactory,
};
