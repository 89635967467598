var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Vue from 'vue';
import loadAllPages from '@/shared/api/farmdokRestApi/utils/loadAllPages';
import mergeApiResponses from '@/shared/api/farmdokRestApi/utils/mergeApiResponses';
import subscribableData from '@/shared/mixins/store/subscribableData/index';
import { availableFeatures } from '@/shared/storeDynamicFeatures';
function fetchAll(context, axiosOptions) {
    return __awaiter(this, void 0, void 0, function* () {
        const companyIds = context.rootState.auth.currentCompanies.map((company) => company.id);
        const { equipmentsApi } = Vue.prototype.$api;
        const apiResponses = yield Promise.all(companyIds.map((companyId) => {
            const requestParameters = { companyId };
            return loadAllPages(equipmentsApi, 'equipmentList', requestParameters, axiosOptions);
        }));
        const apiResponse = mergeApiResponses(apiResponses);
        return apiResponse;
    });
}
function fetchByIds(
// eslint-disable-next-line @typescript-eslint/no-unused-vars
ids, 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
context, 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
axiosOptions) {
    return __awaiter(this, void 0, void 0, function* () {
        const { equipmentsApi } = Vue.prototype.$api;
        const { data } = yield equipmentsApi.equipmentObjects({ objectIds: ids.join(',') }, axiosOptions);
        return data;
    });
}
const subscribableStore = subscribableData(fetchAll, fetchByIds, [availableFeatures.FEATURE_EQUIPMENT]);
export default subscribableStore;
