export default class ActivityRuleViolationService {
    constructor(activityRuleViolations) {
        this.activityRuleViolations = activityRuleViolations;
    }
    getActivityRuleViolations(activityId) {
        if (!activityId)
            return this.activityRuleViolations;
        return this.activityRuleViolations.filter((activityRuleViolation) => activityRuleViolation.activityId === activityId);
    }
}
