import { initialState } from '@/products/store/productsModuleFactory';
import productsModuleStore from './common';
import mutations from './mutations';
const vuexModule = {
    namespaced: true,
    state: initialState(),
    mutations,
    actions: Object.assign({}, productsModuleStore.actions),
    getters: Object.assign({}, productsModuleStore.getters),
};
export default vuexModule;
