var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import axios from 'axios';
import { BASE_PATH } from '../common';
import { apiOptionsToSearchParams } from '../requestUtils';
// eslint-disable-next-line import/prefer-default-export
export function fetchActivityTypesRough(axiosOptions, apiOptions = { itemsPerPage: 5000 }) {
    return __awaiter(this, void 0, void 0, function* () {
        const searchParams = apiOptionsToSearchParams(apiOptions);
        searchParams.append('version', '2.0');
        const url = `${BASE_PATH}/activityTypesRough?${searchParams.toString()}`;
        return axios.get(url, axiosOptions);
    });
}
